
import { defineComponent, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { website, rights, user } from '@/utils/webSideInfo'

export default defineComponent({
  setup () {
    const form = reactive({
      account: '',
      password: ''
    })
    const router = useRouter()
    const store = useStore()
    const toHome = () => {
      router.push({ path: '/' })
    }
    const login = () => {
      console.log(form)
      if (form.account !== user.account) {
        ElMessage.error('账号错误或不存在！')
      } else if (form.password !== user.password) {
        ElMessage.error('密码错误，请重新输入！')
        form.password = ''
      } else {
        store.commit('SET_PERMISSION', null)
        router.push({ path: '/admin' })
        window.localStorage.setItem('token', user.token)
      }
    }
    return { website, rights, user, form, toHome, login }
  }
})
